<template>
  <div class="main-manager-employee">
    <b-card title="Quản lý nhân viên">
      <b-row>
        <b-col lg="3" md="3" sm="3" xs="12">
          <b-form-group>
            <label class="font-weight-bold font-size-label-custom">Tên nhân viên</label>
            <b-form-input @input="getListEmployee" v-model="modelSearchEmployee.fullName" @keyup.enter="getListEmployee" placeholder="Nhập Tên nhân viên tìm kiếm"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="3" sm="3" xs="12">
          <b-form-group>
            <label class="font-weight-bold font-size-label-custom">Mã nhân viên</label>
            <b-form-input @input="getListEmployee" v-model="modelSearchEmployee.employeeCode" @keyup.enter="getListEmployee" placeholder="Nhập mã nhân viên tìm kiếm"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="3" sm="3" xs="12">
          <div class="status-select">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Trạng thái</label>
              <v-select @input="getListEmployee" placeholder="Chọn trạng thái" label="text" v-model="modelSearchEmployee.activeStatus" :options="listStatusEmployee" :reduce="status => status.value" ></v-select>
            </b-form-group>
          </div>
        </b-col>
        <b-col lg="3" md="3" sm="3" xs="12">
          <div class="acction-search" >
            <b-button  @click="getListEmployee()" variant="outline-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span class="align-middle">Tìm kiếm</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3" md="3" sm="3" xs="12">
          <b-form-group>
            <label class="font-weight-bold font-size-label-custom">Phòng ban</label>
            <v-select placeholder="Chọn phòng ban" v-model="modelSearchEmployee.departmentId" label="organizationBranchName"
                      @input="getListDepartmentChildren" :options="listDepartment" :reduce="(department) => department.id" ></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="3" sm="3" xs="12">
          <b-form-group v-if="listDepartmentChildren.length > 0 && modelSearchEmployee.departmentId">
            <label class="font-weight-bold font-size-label-custom">Khối</label>
            <v-select placeholder="Chọn khối" v-model="modelSearchEmployee.partId" label="organizationBranchName"
                      @input="getListDepartmentChildrenPart" :options="listDepartmentChildren" :reduce="(part) => part.id" ></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="3" sm="3" xs="12">
          <b-form-group v-if="listUnitDepartmentChildren.length > 0 && modelSearchEmployee.partId">
            <label class="font-weight-bold font-size-label-custom">Tổ</label>
            <v-select placeholder="Chọn khối" v-model="modelSearchEmployee.unitId" label="organizationBranchName"
                      @input="getListEmplyoeeWithUnitId" :options="listUnitDepartmentChildren" :reduce="(unit) => unit.id" ></v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" xs="12" md="12">
          <div class="acction-add-employee mt-2">
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Tải file"
              variant="outline-primary"
              class="pr-2"
            >
              <b-dropdown-item @click="fileDefaultFormServer">
                Tải file mẫu</b-dropdown-item>
              <b-dropdown-item @click="getModalPushFileExcel">Tạo nhân viên bằng file excel
              </b-dropdown-item>
              <b-dropdown-item @click="exportFileExcelEmployee">Xuất file nhân viên</b-dropdown-item>
            </b-dropdown>
            <b-button height="12" variant="outline-primary" v-b-modal.modal-1 @click="addEployee" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            >
              <feather-icon icon="PlusIcon"
              />
              <span class="align-middle">Thêm mới</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <div class="table-data-employee mt-2">
            <span style="color: #059862;">Có tổng {{currentTotalRecords}} bản ghi</span>
            <b-table
              responsive
              :items="listEmployee"
              :current-page="currentPage2"
              :per-page="perPage2"
              :fields="fields"
              class="mb-0"
            >
              <template #cell(activeStatus)="data">
                <b-badge v-if="data.value == 0" variant="danger" style="color: #ffff">
                  Đã nghỉ việc
                </b-badge>
                <b-badge v-if="data.value == 1" variant="success" style="color: #ffff">
                  Đang làm việc
                </b-badge>
                <b-badge v-if="data.value == '2'" variant="warning" style="color: #ffff">
                  Tạm dừng công việc
                </b-badge>
              </template>
              <template #cell(hanhdong)="data">
                <b-button @click="getFormUpdateEmployee(data.item)" v-b-tooltip.hover.top="'Cập nhật'" variant="flat-primary" class="btn-icon"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button v-b-tooltip.hover.top="'Xóa'" @click="deleteEmployee(data.item)"
                          variant="flat-danger"
                          class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
            <div class="pagination-custom mt-2">
              <template>
                <b-pagination
                  v-model="currentPage2"
                  :per-page="perPage2"
                  hide-goto-end-buttons
                  :total-rows="rowData"
                />
              </template>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="modal-file"
      size="sm"
      hide-header
      hide-footer
    >
      <div class="title-header-modal">
        <h4 class="card-title">Tạo mới nhân viên bằng file excel</h4>
      </div>
      <div class="main-push-file">
        <label for="wildcard">Chọn file</label>
        <b-form-file
          @input="pushFileExcel"
          browse-text="Chọn file"
          id="wildcard"
          accept=".xlsx, .xlsm"
          placeholder="Vui lòng chọn file"
        />
      </div>
      <div class="footer-modal-push-file mt-2">
        <b-button v-if="isClickFile === true" @click="acctionPushFile" size="sm" variant="outline-success" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Tải file</span>
        </b-button>
        <b-button v-if="isClickFile === false" disabled size="sm" variant="outline-success" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Tải file</span>
        </b-button>
        <b-button class="ml-1" @click="closeModal" size="sm" variant="outline-danger" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span class="align-middle">Đóng</span>
        </b-button>
      </div>
    </b-modal>
    <b-modal
      ref="modal-file-error"
      size="xl"
      hide-header
      hide-footer
    >
      <div class="title-header-modal">
        <h4 class="card-title">Danh sách tạo nhân viên lỗi</h4>
      </div>
      <div class="main-modal-errors">
        <b-table
          responsive
          :items="listFileErrors"
          :fields="fieldImport"
          :per-page="perPage"
          :current-page="currentPageFile"
          class="mb-0" >
          :small="small"
          <template #cell(error)="data">
            <b-badge variant="danger">
              {{data.value}}
            </b-badge>
          </template>
        </b-table>
        <div class="pagination-page-file-errors">
          <b-pagination
            v-model="currentPageFile"
            :per-page="perPage"
            :total-rows="rows"
            hide-goto-end-buttons
          />
        </div>
      </div>
      <div class="footer-errors-page">
        <b-button class="ml-1" @click="getModalPushFileExcel" size="sm" variant="outline-success" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        >
          <feather-icon
            icon="CornerDownLeftIcon"
            class="mr-50"
          />
          <span class="align-middle">Quay lại chọn file</span>
        </b-button>
        <b-button class="ml-1" @click="closeModal" size="sm" variant="outline-warning" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span class="align-middle">Đóng</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BPagination, BFormInput, VBTooltip, BBadge, BFormGroup, BModal, BDropdown, BDropdownItem, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BFormInput,
    vSelect,
    BBadge,
    BFormGroup,
    BModal,
    BDropdown,
    BDropdownItem,
    BFormFile,
  },
  mixins: [heightTransition],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  computed: {
    rows() {
      if (this.listFileErrors) {
        return this.listFileErrors.length
      }
    },
    rowData() {
      if (this.listEmployee) {
        return this.listEmployee.length
      }
    },
  },
  data() {
    return {
      perPage: 6,
      currentPage2: 1,
      perPage2: 10,
      isGetModalPush: false,
      required,
      file: {
        name: '',
      },
      isModalFile: false,
      listFileErrors: [],
      fileNamePush: '',
      fileExportServe: '',
      totalNumberPage: null,
      currentPage: 1,
      listPushEmployee: {
        listEmployeeId: [
        ],
      },
      fieldImport: [
        {
          key: 'index', label: 'Stt', tdClass: 'customClass',
        },
        {
          key: 'row', label: 'Dòng', tdClass: 'customClass',
        },

        {
          key: 'columnError', label: 'cột', tdClass: 'customClassColum',
        },
        {
          key: 'error', label: 'Lỗi', tdClass: 'customTextWidth',
        },
      ],
      currentPageFile: 1,
      idUser: this.$route.query,
      isUpdate: false,
      imgSrc: '',
      totalRecordsList: 1,
      currentTotalRecords: 0,
      modelEmployee: {},
      modelSearchEmployee: {
        fullName: '',
        employeeCode: '',
        organizationBranchSchoolId: null,
        activeStatus: null,
        pageIndex: 1,
        firstItemOnPage: 1,
        pageSize: 10,
        pageNumber: 1,
        perPage: 1,
        departmentId: null,
        partId: null,
        unitId: null,
      },
      modelImportPaging: {
        pageNumber: 1,
        records: null,
        totalRecords: null,
        pageIndex: 1,
        pageSize: 1,
      },
      isClickFile: false,
      listDepartment: [],
      listDepartmentChildren: [],
      listUnitDepartmentChildren: [],
      fields: [
        {
          key: 'employeeCode', label: 'Mã NV',
        },
        {
          key: 'fullName', label: 'Tên NV',
        },
        {
          key: 'organizationBranchSchoolName', label: 'Chức vụ',
        },
        {
          key: 'email', label: 'Email',
        },
        {
          key: 'activeStatus', label: 'Trạng thái', thClass: 'custom-class-td',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-class-th-a',
        },
      ],
      listEmployee: [],
      listStatusEmployee: [
        {
          id: 1,
          text: 'Đã nghỉ việc',
          value: 0,
        },
        {
          id: 2,
          text: 'Đang làm việc',
          value: 1,
        },
        {
          id: 3,
          text: 'Tạm dừng công việc',
          value: 2,
        },
      ],
    }
  },
  methods: {
    addEployee() {
      this.$router.push('/manager-employee/add-employee')
    },
    preview(e) {
      const sizeInBytes = e.target.files[0].size
      if (sizeInBytes > 5242880) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Lỗi tải file',
            icon: 'StopIcon',
            text: 'File tải lên không được vượt quá 5Mb',
          },
        })
      }
      const imgSrc = URL.createObjectURL(e.target.files[0])
      this.imgSrc = imgSrc
    },
    getFormUpdateEmployee(item) {
      this.$router.push({
        name: 'manager-employee/update-employee',
        query: {
          id: item.id,
          name: item.fullName,
        },
      })
    },
    deleteEmployee(item) {
      this.$swal({
        title: 'Xóa nhân viên',
        text: `Bạn có muốn xóa nhân viên ${item.fullName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.post(`employee-school/delete/${item.id}`).then(res => {
            this.getListEmployee()
            this.$swal({
              icon: 'success',
              title: 'Xóa nhân sự!',
              text: 'Xóa nhân sự thành công',
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
    getListAllDepartment() {
      this.modelSearchEmployee.departmentId = null
      this.modelSearchEmployee.partId = null
      this.modelSearchEmployee.unitId = null
      this.listDepartmentChildren = []
      this.listUnitDepartmentChildren = []
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
        this.getListEmployee()
      })
    },
    getListDepartmentChildren(id) {
      this.listUnitDepartmentChildren = []
      this.modelSearchEmployee.partId = null
      this.modelSearchEmployee.organizationBranchSchoolId = id
      this.modelSearchEmployee.pageIndex = 1
      if (id) {
        this.$crm.get(`organization-branch-school/find-children/${id}`).then(res => {
          this.listDepartmentChildren = res.data
          this.getListEmployee()
        })
      } else {
        this.getListEmployee()
      }
    },
    getListDepartmentChildrenPart(id) {
      this.modelSearchEmployee.pageIndex = 1
      this.modelSearchEmployee.organizationBranchSchoolId = id
      this.modelSearchEmployee.unitId = null
      if (id) {
        this.$crm.get(`organization-branch-school/find-children/${id}`)
          .then(res => {
            this.listUnitDepartmentChildren = res.data
            this.getListEmployee()
          })
      } else {
        this.modelSearchEmployee.organizationBranchSchoolId = this.modelSearchEmployee.departmentId
        this.getListEmployee()
      }
    },
    getListEmplyoeeWithUnitId(id) {
      if (id) {
        this.modelSearchEmployee.organizationBranchSchoolId = id
        this.getListEmployee()
      } else {
        this.modelSearchEmployee.organizationBranchSchoolId = this.modelSearchEmployee.partId
        this.getListEmployee()
      }
    },
    getListEmployee() {
      // this.listEmployee = []
      this.modelSearchEmployee.pageIndex = 1
      this.$crm.post('employee-school/all', this.modelSearchEmployee)
        .then(res => {
          this.listEmployee = res.data
          for (let i = 0; i < this.listEmployee.length; i++) {
            this.listPushEmployee.listEmployeeId.push(this.listEmployee[i].id)
          }
          this.currentTotalRecords = this.listEmployee.length
        })
    },
    getModalPushFileExcel() {
      if (this.isGetModalPush === false) {
        this.$refs['modal-file'].show()
      } else {
        this.file = {}
        this.$refs['modal-file'].show()
        this.$refs['modal-file-error'].hide()
      }
      this.isModalFile = false
    },
    closeModal() {
      if (this.isModalFile === false) {
        this.$refs['modal-file'].hide()
      } else {
        this.$refs['modal-file-error'].hide()
      }
    },
    fileDefaultFormServer() {
      this.$crm.post('employee-school/downloadTemplate')
        .then(res => {
          this.fileNamePush = res.data.fileName
          this.getFileDefault()
        })
    },
    getFileDefault() {
      if (this.fileNamePush !== '') {
        this.$crm.get(`file/${this.fileNamePush}`)
          .then(res => {
            const accesstoken = localStorage.getItem('access_token')
            window.open(`${res.config.baseURL}${res.config.url}?access_token=${accesstoken}`, '_blank')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thành công',
                icon: 'CheckIcon',
                text: 'Lấy file mẫu thành công',
              },
            })
          })
      }
    },
    exportFileExcelEmployee() {
      this.$crm.post('employee-school/export', this.modelSearchEmployee)
        .then(res => {
          this.fileExportServe = res.data.fileName
          this.getFileExcel()
        })
    },
    getFileExcel() {
      if (this.fileExportServe !== '') {
        this.$crm.get(`file/${this.fileExportServe}`)
          .then(res => {
            const accesstoken = localStorage.getItem('access_token')
            window.open(`${res.config.baseURL}${res.config.url}?access_token=${accesstoken}`, '_blank')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thành công',
                icon: 'CheckIcon',
                text: 'Tải file thành công',
              },
            })
          })
      }
    },
    pushFileExcel(file) {
      if (file) {
        this.file = file
        this.isClickFile = true
      } else {
        this.file = ''
        this.isClickFile = false
      }
    },
    acctionPushFile() {
      if (this.file.name && this.file !== null && this.isClickFile === true) {
        const formData = new FormData()
        formData.append('file', this.file)
        this.$crm.post('employee-school/import', formData)
          .then(res => {
            this.listFileErrors = res.data.employeeSchoolImportErrors
            if (this.listFileErrors && this.listFileErrors.length > 0) {
              this.$refs['modal-file-error'].show()
              this.$refs['modal-file'].hide()
              this.isGetModalPush = true
              this.isModalFile = true
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Lỗi',
                  icon: 'XIcon',
                  text: 'Tải file thất bại',
                  variant: 'danger',
                },
              })
            } else {
              this.totalRecordsList += 1
              this.getListEmployee()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Thành công',
                  icon: 'CheckIcon',
                  text: 'Tải file lên thành công',
                  variant: 'success',
                },
              })
              // window.location.reload()
              this.$refs['modal-file'].hide()
            }
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'lỗi',
            icon: 'XIcon',
            text: 'Chưa chọn file',
            variant: 'danger',
          },
        })
      }
    },
  },
  created() {
    this.getListAllDepartment()
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/custom/manger-employee';
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-sweetalert.scss';
</style>
